import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1>
          Dreamcode App
        </h1>
      </header>
      <div className="App-privacy-policy" id="privacy-policy">
        <h4>Privacy Policy</h4>
        <p>
          <strong>Privacy Policy for "NumGen Pro"</strong><br />
          Last updated: 11 May 2024<br />
          This Privacy Policy describes the practices concerning information collected by [Company Name] (the "Developer," "we," "us," or "our") through the use of our mobile application, Numbers - Generate and Analyse (the "App").
        </p>

        <p>
          <strong>1. Information We Do Not Collect</strong><br />
          The App does not collect or store any personal information from its users. We do not require users to provide personal information such as names, email addresses, or phone numbers at any point while downloading or using our App.
        </p>

        <p>
          <strong>2. Non-Personal Data</strong><br />
          While our App does not collect personal information, we may collect non-personal data about your device such as your software version, phone model, and other non-identifiable data to ensure the best possible application performance and user experience. This data does not allow us to identify users personally.
        </p>

        <p>
          <strong>3. Use of Non-Personal Data</strong><br />
          The non-personal data we collect is used solely for the purpose of improving the App's functionality and resolving issues such as crashes or bugs. This information helps us ensure that our App remains compatible with the most popular devices and operating systems.
        </p>

        <p>
          <strong>4. Third-Party Services</strong><br />
          Our App displays advertisements provided by Google Ads. As such, Google may collect data from users to personalize advertising. This data collection is subject to Google's Privacy Policy.  We do not control and are not responsible for the data collected by Google or other third-party advertisers. We recommend reviewing Google's Privacy Policy to understand the specific types of information Google collects and how it is used and managed.  If you prefer not to receive personalized ads, you can adjust your ad preferences through Google's Ads Settings, where you can also manage other privacy settings.  For more information on Google's privacy practices, please visit [Google's Privacy & Terms](https://policies.google.com/privacy).
        </p>

        <p>
          <strong>5. Changes to Our Privacy Policy</strong><br />
          We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
        </p>

        <p>
          <strong>6. Contact Us</strong><br />
          If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us
        </p>
      </div>
    </div>
  );
}

export default App;
